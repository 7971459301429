import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

const PricingPlanProps = {
  plan: {
    name: String,
    monthlyPrice: String,
    annualPrice: String,
    period: String,
    monthlyBilling: String,
    annualBilling: String,
    note: String,
    features: [String],
    cta: String,
    highlight: Boolean,
  },
  darkMode: Boolean,
  annualBilling: Boolean,
  highlight: Boolean,
};

const TeamPlanCalculator = ({ darkMode, annualBilling }) => {
  const [userCount, setUserCount] = useState(3);
  const monthlyPrice = 15.99;
  const annualPrice = 12.99;

  const calculateCost = () => {
    const basePrice = annualBilling ? annualPrice : monthlyPrice;
    return (basePrice * userCount).toFixed(2);
  };

  return (
    <div className="mt-6">
      <label 
        htmlFor="user-count" 
        className="block mb-2 text-sm font-medium text-gray-800"
      >
        Number of Users: {userCount}
      </label>
      <input
        id="user-count"
        type="range"
        min="3"
        max="100"
        value={userCount}
        onChange={(e) => setUserCount(Number(e.target.value))}
        className="w-full h-2 bg-gradient-to-r from-indigo-200 to-indigo-300 rounded-lg appearance-none cursor-pointer"
      />
      <div className="mt-1 mb-2.5 text-center">
        <span className="text-2xl font-bold text-gray-900">
          ${calculateCost()}
        </span>
        <span className="text-sm text-gray-600">
          {annualBilling ? "/month billed annually" : "/month"}
        </span>
      </div>
    </div>
  );
};

function PricingPage() {
  const [darkMode, setDarkMode] = useState(false);
  const [logoFade, setLogoFade] = useState(false);
  const [annualBilling, setAnnualBilling] = useState(true);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);

  const handleDarkModeToggle = () => {
    setLogoFade(true);
    setTimeout(() => {
      setDarkMode(!darkMode);
      setLogoFade(false);
    }, 150);
  };

  const PricingPlan = ({
    plan,
    darkMode,
    annualBilling,
    highlight = false,
  }) => (
    <div
      className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl ${
        darkMode ? "bg-gray-800" : "bg-white"
      } ${highlight ? "ring-2 ring-indigo-500" : ""} flex flex-col h-full`}
      onClick={() => (window.location.href = "/signup")} // Redirect to signup on click
    >
      <div className="flex-grow">
        <h3
          className={`text-2xl font-bold mb-4 ${
            darkMode ? "text-indigo-300" : "text-indigo-700"
          }`}
        >
          {plan.name}
        </h3>
        {plan.name === "Boxia Team" ? (
          <TeamPlanCalculator darkMode={darkMode} annualBilling={annualBilling} />
        ) : plan.name === "Boxia Enterprise" ? (
          <div className="mb-2">
            <span
              className={`text-2xl font-bold ${
                darkMode ? "text-gray-100" : "text-gray-900"
              }`}
            >
              Contact Sales
            </span>
          </div>
        ) : (
          <>
            <div className="mb-2">
              <span
                className={`text-4xl font-bold ${
                  darkMode ? "text-gray-100" : "text-gray-900"
                }`}
              >
                {annualBilling ? plan.annualPrice : plan.monthlyPrice}
              </span>
              <span
                className={`text-lg ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {plan.period}
              </span>
            </div>
            {(annualBilling ? plan.annualBilling : plan.monthlyBilling) && (
              <p
                className={`mb-2 text-sm ${
                  darkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {annualBilling ? plan.annualBilling : plan.monthlyBilling}
              </p>
            )}
          </>
        )}
        {plan.note && (
          <p
            className={`mb-4 text-sm font-medium ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            {plan.note}
          </p>
        )}
        <ul className="mb-8 space-y-2">
          {plan.features.map((feature, featureIndex) => (
            <li
              key={featureIndex}
              className={`flex items-center ${
                darkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              <svg
                className="w-5 h-5 mr-2 text-green-500 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto">
        <button
          className={`w-full py-2 px-4 rounded-full font-medium ${
            highlight
              ? "bg-indigo-600 text-white hover:bg-indigo-700"
              : darkMode
              ? "bg-gray-700 text-white hover:bg-gray-600"
              : "bg-gray-200 text-gray-800 hover:bg-gray-300"
          } transition-colors duration-200`}
        >
          {plan.cta}
        </button>
      </div>
    </div>
  );

  const plans = [
    {
      name: "Boxia Personal",
      monthlyPrice: "$9.99",
      annualPrice: "$7.99",
      period: "/month",
      monthlyBilling: "billed monthly",
      annualBilling: "billed annually",
      features: [
        "All core Boxia features",
        "10GB of Boxia Cloud Storage",
        "Boxia Copilot Basic",
        "Up to 5 integrations",
      ],
      cta: "Start 14-day free trial",
    },
    {
      name: "Boxia Pro",
      monthlyPrice: "$17.99",
      annualPrice: "$14.99",
      period: "/month",
      monthlyBilling: "billed monthly",
      annualBilling: "billed annually",
      features: [
        "All Personal features",
        "100GB of Boxia Cloud Storage",
        "Boxia Copilot Pro",
        "Unlimited integrations",
        "Priority support",
        "Advanced customization",
        "Workflow automation",
      ],
      cta: "Start 14-day free trial",
      highlight: true,
    },
    {
      name: "Boxia Team",
      monthlyPrice: "$15.99",
      annualPrice: "$12.99",
      period: "/user/month",
      monthlyBilling: "billed monthly",
      annualBilling: "billed annually",
      note: "Minimum 3 users",
      features: [
        "All Pro features",
        "Team collaboration tools",
        "Shared workspaces",
        "Admin controls",
        "Team analytics",
        "Enhanced security features",
      ],
      cta: "Start Team Plan",
    },
    {
      name: "Boxia Enterprise",
      monthlyPrice: "Custom",
      annualPrice: "Custom",
      period: "Custom",
      features: [
        "All Team features",
        "Custom integrations",
        "Advanced security (SSO)",
        "Dedicated account manager",
        "Custom training & onboarding",
      ],
      cta: "Contact Sales",
    },
  ];

  return (
    <main
      className={`flex-grow overflow-y-auto transition-colors duration-300 ${
        darkMode ? "dark text-white" : "text-gray-800"
      } ${
        darkMode
          ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800"
          : "bg-gradient-to-b from-indigo-50 via-white to-indigo-100"
      }`}
    >
      <NavBar darkMode={darkMode} logoFade={logoFade} />

      {/* Hero Section */}
      <section className="pt-[200px] py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1
            className={`text-4xl sm:text-5xl md:text-6xl font-extrabold tracking-tight mb-6 leading-tight ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Simple Pricing for{" "}
            <span className="text-indigo-600 dark:text-indigo-400">
              Powerful Productivity
            </span>
          </h1>
          <p
            className={`text-xl sm:text-2xl mb-8 max-w-2xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Choose the plan that fits your needs and start boosting your
            productivity today.
          </p>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex justify-center mb-12">
            <div
              className={`p-1 rounded-full ${
                darkMode ? "bg-gray-700" : "bg-gray-200"
              } relative w-72`}
            >
              <motion.div
                className={`absolute top-1 bottom-1 left-1 rounded-full ${
                  darkMode ? "bg-indigo-600" : "bg-indigo-500"
                }`}
                initial={false}
                animate={{
                  x: annualBilling ? "0%" : "100%",
                  width: "calc(50% - 4px)",
                }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
              />
              <div className="relative z-10 flex">
                <button
                  className={`flex-1 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    annualBilling
                      ? "text-white"
                      : darkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                  onClick={() => setAnnualBilling(true)}
                >
                  Annual Billing
                </button>
                <button
                  className={`flex-1 px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    !annualBilling
                      ? "text-white"
                      : darkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                  onClick={() => setAnnualBilling(false)}
                >
                  Monthly Billing
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {plans.map((plan, index) => (
              <PricingPlan
                key={index}
                plan={plan}
                darkMode={darkMode}
                annualBilling={annualBilling}
                highlight={plan.highlight}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Features Comparison */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2
            className={`text-3xl md:text-4xl font-bold text-center mb-12 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Compare Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {["Personal", "Pro", "Team", "Enterprise"].map(
              (plan, planIndex) => (
                <div
                  key={planIndex}
                  className={`rounded-lg overflow-hidden ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } shadow-lg`}
                >
                  <div
                    className={`p-6 ${
                      darkMode ? "bg-gray-700" : "bg-indigo-100"
                    }`}
                  >
                    <h3
                      className={`text-xl font-semibold ${
                        darkMode ? "text-indigo-300" : "text-indigo-700"
                      }`}
                    >
                      {plan}
                    </h3>
                  </div>
                  <ul className="p-6 space-y-4">
                    {[
                      "All core Boxia features",
                      "Boxia Cloud Storage",
                      "Boxia Copilot",
                      "Integrations",
                      "Priority support",
                      "Advanced customization",
                      "Workflow automation",
                      "Team collaboration tools",
                      "Shared workspaces",
                      "Admin controls",
                      "Team analytics",
                      "Enhanced security features",
                      "Custom integrations",
                      "Advanced security (SSO)",
                      "Dedicated account manager",
                      "Custom training & onboarding",
                    ].map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        {(planIndex === 3) ||
                        (planIndex === 2 && featureIndex < 12) ||
                        (planIndex === 1 && featureIndex < 7) ||
                        (planIndex === 0 && featureIndex < 4) ? (
                          <svg
                            className="w-5 h-5 mr-2 text-green-500 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="w-5 h-5 mr-2 text-red-500 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        )}
                        <span
                          className={`text-sm ${
                            darkMode ? "text-gray-300" : "text-gray-700"
                          }`}
                        >
                          {feature}
                          {(feature === "Boxia Cloud Storage" || feature === "Boxia Copilot" || feature === "Integrations") && (
                            <span className="ml-1 text-xs text-gray-500">
                              {feature === "Boxia Cloud Storage" && (planIndex === 0 ? "(10GB)" : planIndex === 1 ? "(100GB)" : "(Unlimited)")}
                              {feature === "Boxia Copilot" && (planIndex === 0 ? "(Basic)" : "(Pro)")}
                              {feature === "Integrations" && (planIndex === 0 ? "(Up to 5)" : "(Unlimited)")}
                            </span>
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2
            className={`text-3xl md:text-4xl font-bold text-center mb-12 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Frequently Asked Questions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                question: "Can I change my plan later?",
                answer:
                  "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.",
              },
              {
                question: "What payment methods do you accept?",
                answer:
                  "We accept all major credit cards and debit cards through Stripe.",
              },
              {
                question: "Is there a free trial available?",
                answer: "Yes, we offer a 14-day free trial for all our plans.",
              },
              {
                question: "What happens when my trial ends?",
                answer:
                  "At the end of your trial, you will start your paid plan.",
              },
            ].map((faq, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg ${
                  darkMode ? "bg-gray-800" : "bg-white"
                } shadow-md`}
              >
                <h3
                  className={`text-xl font-semibold mb-2 ${
                    darkMode ? "text-indigo-300" : "text-indigo-700"
                  }`}
                >
                  {faq.question}
                </h3>
                <p
                  className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}
                >
                  {faq.answer}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h2
            className={`text-3xl md:text-4xl font-bold mb-6 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Ready to Boost Your Productivity?
          </h2>
          <p
            className={`text-xl mb-12 max-w-3xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Join thousands of professionals who have transformed their workflow
            with Boxia. Start your journey to enhanced productivity today.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-6">
            <button
              onClick={() => (window.location.href = "/signup")}
              className="w-full sm:w-auto px-8 py-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-full text-lg font-semibold transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              Start Free Trial
            </button>
            {/* <button
              className={`w-full sm:w-auto px-8 py-4 border-2 border-indigo-600 rounded-full text-lg font-semibold transition-all duration-200 ${
                darkMode
                  ? "text-indigo-400 hover:bg-indigo-900"
                  : "text-indigo-600 hover:bg-indigo-50"
              }`}
            >
              Schedule a Demo
            </button> */}
          </div>
        </div>
      </section>

      <Footer darkMode={darkMode} />
    </main>
  );
}

export default PricingPage;
