import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

// Import integration logos
import googleLogo from "../assets/images/logos/google.svg";
import microsoftLogo from "../assets/images/logos/microsoft.svg";
import jiraLogo from "../assets/images/logos/jira.svg";
import trelloLogo from "../assets/images/logos/trello.svg";
import slackLogo from "../assets/images/logos/slack.svg";
import githubLogo from "../assets/images/logos/github.svg";
import dropboxLogo from "../assets/images/logos/dropbox.svg";
import salesforceLogo from "../assets/images/logos/salesforce.svg";
import asanaLogo from "../assets/images/logos/asana.svg";
import evernoteLogo from "../assets/images/logos/evernote.svg";
import zapierLogo from "../assets/images/logos/zapier.svg";
import hubspotLogo from "../assets/images/logos/hubspot.svg";

const IntegrationCard = ({ integration, darkMode, index }) => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className={`p-6 rounded-lg ${
        darkMode ? "bg-gray-800" : "bg-white"
      } shadow-lg transition-all duration-300 hover:shadow-xl`}
    >
      <img
        src={integration.logo}
        alt={`${integration.name} logo`}
        className={`h-16 mx-auto mb-4 ${darkMode ? "filter invert" : ""}`}
      />
      <h3
        className={`text-xl font-semibold mb-2 text-center ${
          darkMode ? "text-white" : "text-gray-800"
        }`}
      >
        {integration.name}
      </h3>
      <p
        className={`text-center ${
          darkMode ? "text-gray-300" : "text-gray-600"
        }`}
      >
        {integration.description}
      </p>
    </motion.div>
  );
};

function IntegrationsPage() {
  const [darkMode, setDarkMode] = useState(false);
  const [logoFade, setLogoFade] = useState(false);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);

  const handleDarkModeToggle = () => {
    setLogoFade(true);
    setTimeout(() => {
      setDarkMode(!darkMode);
      setLogoFade(false);
    }, 150);
  };

  const integrations = [
    {
      name: "Google",
      logo: googleLogo,
      description: "Integrate seamlessly with Google Workspace apps",
    },
    {
      name: "Microsoft",
      logo: microsoftLogo,
      description: "Enhance productivity with Microsoft 365 integration",
    },
    {
      name: "Jira",
      logo: jiraLogo,
      description: "Optimize project management with Jira integration",
    },
    {
      name: "Trello",
      logo: trelloLogo,
      description: "Better task management with Trello board sync",
    },
    {
      name: "Slack",
      logo: slackLogo,
      description: "Stay connected with Slack team integration",
    },
    {
      name: "GitHub",
      logo: githubLogo,
      description: "Manage code repositories and projects with GitHub",
    },
    {
      name: "Dropbox",
      logo: dropboxLogo,
      description: "Access Dropbox files directly within Boxia",
    },
    {
      name: "Salesforce",
      logo: salesforceLogo,
      description: "Unify workflow with Salesforce CRM data integration",
    },
    {
      name: "Asana",
      logo: asanaLogo,
      description: "Sync Asana tasks seamlessly with Boxia",
    },
    {
      name: "Evernote",
      logo: evernoteLogo,
      description: "Import Evernote notes directly into Boxia",
    },
    {
      name: "Zapier",
      logo: zapierLogo,
      description: "Connect Boxia with thousands of apps via Zapier",
    },
    {
      name: "HubSpot",
      logo: hubspotLogo,
      description: "Integrate marketing and sales data with HubSpot",
    },
  ];

  return (
    <main
      className={`flex-grow overflow-y-auto transition-colors duration-300 ${
        darkMode ? "dark text-white" : "text-gray-800"
      } ${
        darkMode
          ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800"
          : "bg-gradient-to-b from-indigo-50 via-white to-indigo-100"
      }`}
    >
      <NavBar darkMode={darkMode} logoFade={logoFade} />

      {/* Hero Section */}
      <section className="pt-[200px] py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1
            className={`text-4xl sm:text-5xl md:text-6xl font-extrabold tracking-tight mb-6 leading-tight ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Seamless{" "}
            <span className="text-indigo-600 dark:text-indigo-400">
              Integrations
            </span>
          </h1>
          <p
            className={`text-xl sm:text-2xl mb-8 max-w-2xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Connect Boxia with your favorite tools and boost your productivity
          </p>
        </div>
      </section>

      {/* Integrations Grid */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {integrations.map((integration, index) => (
              <IntegrationCard
                key={index}
                integration={integration}
                darkMode={darkMode}
                index={index}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section
        className={`py-24 px-4 sm:px-6 lg:px-8 ${
          darkMode ? "bg-gray-900" : "bg-gray-50"
        }`}
      >
        <div className="max-w-7xl mx-auto">
          <h2
            className={`text-3xl md:text-4xl font-bold text-center mb-12 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Benefits of Boxia Integrations
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Streamlined Workflow",
                description:
                  "Connect all your tools for a seamless work experience",
                icon: "🔄",
              },
              {
                title: "Time-Saving Automation",
                description:
                  "Automate repetitive tasks across multiple platforms",
                icon: "⏱️",
              },
              {
                title: "Centralized Information",
                description: "Access all your data from a single dashboard",
                icon: "📊",
              },
              {
                title: "Enhanced Collaboration",
                description:
                  "Work together more efficiently with integrated tools",
                icon: "🤝",
              },
              {
                title: "Customizable Integrations",
                description: "Tailor integrations to fit your unique workflow",
                icon: "🛠️",
              },
              {
                title: "Scalable Solutions",
                description:
                  "Easily add or remove integrations as your needs change",
                icon: "📈",
              },
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`p-6 rounded-lg ${
                  darkMode ? "bg-gray-800" : "bg-white"
                } shadow-lg`}
              >
                <div className="text-4xl mb-4">{benefit.icon}</div>
                <h3
                  className={`text-xl font-semibold mb-2 ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {benefit.title}
                </h3>
                <p
                  className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}
                >
                  {benefit.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2
            className={`text-3xl md:text-4xl font-bold text-center mb-12 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            How Boxia Integrations Work
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Connect",
                description:
                  "Choose from our wide range of integrations and connect your accounts",
                icon: "🔌",
              },
              {
                title: "Configure",
                description:
                  "Set up your integration preferences and customize the data flow",
                icon: "⚙️",
              },
              {
                title: "Collaborate",
                description:
                  "Start working more efficiently with your integrated tools",
                icon: "🚀",
              },
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`p-6 rounded-lg ${
                  darkMode ? "bg-gray-800" : "bg-white"
                } shadow-lg text-center`}
              >
                <div className="text-5xl mb-4">{step.icon}</div>
                <h3
                  className={`text-2xl font-semibold mb-2 ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {step.title}
                </h3>
                <p
                  className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}
                >
                  {step.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section
        className={`py-24 px-4 sm:px-6 lg:px-8 ${
          darkMode ? "bg-gray-900" : "bg-indigo-50"
        }`}
      >
        <div className="max-w-7xl mx-auto text-center">
          <h2
            className={`text-3xl md:text-4xl font-bold mb-6 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Ready to Supercharge Your Workflow?
          </h2>
          <p
            className={`text-xl mb-12 max-w-3xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Start integrating your favorite tools with Boxia and experience
            seamless productivity.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-6">
            <button
              onClick={() => (window.location.href = "/signup")}
              className="w-full sm:w-auto px-8 py-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-full text-lg font-semibold transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              Get Started for Free
            </button>
            <button
              className={`w-full sm:w-auto px-8 py-4 border-2 border-indigo-600 rounded-full text-lg font-semibold transition-all duration-200 ${
                darkMode
                  ? "text-indigo-400 hover:bg-indigo-900"
                  : "text-indigo-600 hover:bg-indigo-50"
              }`}
            >
              Schedule a Demo
            </button>
          </div>
        </div>
      </section>

      <Footer darkMode={darkMode} />
    </main>
  );
}

export default IntegrationsPage;
