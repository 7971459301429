import React from "react";
import boxiaCircleLogo from "../assets/images/Boxia Circle Main.png";

const Footer = ({ darkMode }) => {
  const footerSections = [
    {
      title: "Product",
      items: [
        { name: "Features", href: "/features" },
        { name: "Pricing", href: "#" },
        { name: "Integrations", href: "#" },
        { name: "FAQ", href: "#" },
      ],
    },
    {
      title: "Company",
      items: [
        { name: "About Us", href: "#" },
        { name: "Careers", href: "#" },
        { name: "Blog", href: "#" },
        { name: "Press", href: "#" },
      ],
    },
    {
      title: "Resources",
      items: [
        { name: "Documentation", href: "#" },
        { name: "Tutorials", href: "#" },
        { name: "Support", href: "#" },
        { name: "API", href: "#" },
      ],
    },
    {
      title: "Legal",
      items: [
        { name: "Privacy Policy", href: "#" },
        { name: "Terms of Service", href: "#" },
        { name: "Cookie Policy", href: "#" },
        { name: "GDPR", href: "#" },
      ],
    },
  ];

  return (
    <footer
      className={`py-16 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
        darkMode
          ? "bg-gradient-to-b from-gray-900 to-black text-gray-300"
          : "bg-gradient-to-b from-indigo-50 to-white text-gray-700"
      }`}
    >
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-12 mb-16">
          {footerSections.map((section, index) => (
            <div key={index} className="space-y-6">
              <h4 className="text-lg font-semibold tracking-wide">{section.title}</h4>
              <ul className="space-y-3">
                {section.items.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className={`text-sm hover:text-indigo-500 transition-colors duration-200 hover:underline`}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center pt-8 border-t border-gray-700">
          <div
            className="flex items-center mb-6 md:mb-0 cursor-pointer group"
            onClick={() => (window.location.href = "/")}
          >
            <img
              src={boxiaCircleLogo}
              alt="Boxia Logo"
              className="w-10 h-10 mr-3 group-hover:rotate-12 transition-transform duration-300"
            />
            <span className="text-2xl font-bold tracking-tight group-hover:text-indigo-500 transition-colors duration-200">Boxia</span>
          </div>
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8">
            <a
              href="https://github.com/BoxiaApp"
              className="text-sm hover:text-indigo-500 transition-colors duration-200 hover:underline"
            >
              GitHub
            </a>
            <span className="text-sm">
              © {new Date().getFullYear()} Boxia. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
